// routes
import { SALESPERSON_LOGIN, AGENT_LOGIN, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  { title: 'How It Works', path: PATH_PAGE.howitworks },
  { title: 'FAQs', path: PATH_PAGE.faqs },
  { title: 'Contact us', path: PATH_PAGE.contact },
  { title: 'Buy/Login', path: PATH_PAGE.buy },
  // {
  //   title: 'Contact us',
  //   icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
  //   childern: [
  //     {
  //       subheader: 'Login',
  //       item: [
  //         { title: 'Agent' },
  //         { title: 'SalesPerson' },
  //       ]
  //     }
  //   ]
  // },

  // {
  //   title: 'Login',
  //   path: '/Login',
  //   icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'Login',
  //       items: [
  //         { title: 'Agent', path: AGENT_LOGIN },
  //         { title: 'Salesperson', path: SALESPERSON_LOGIN },
  //       ],
  //     },
  //   ],
  // },
];

export default menuConfig;
